<template>
  <div>
    <div>
      <h3>Роль объекта (последние 24 часа)</h3>
      <div class="col-md-12 text-right">
        <b-button variant="outline-primary" @click="updateSeries" size="md">
          {{ getSeriesTypeText() }}
        </b-button>
      </div>
      <div class="col-12">
        <apexchart ref="theChart" type="line" height="350" :options="chartOptions" :series="series" />
      </div>
    </div>
    <div class="table-responsive bg-white shadow rounded">
      <table class="table nocolor mb-0 table-center" style="table-layout:fixed;">
        <thead>
          <tr>
            <th scope="col" style="width:7%; text-align:center; cursor: pointer;">
              ID Сюжета
            </th>
            <th scope="col" style="width:65%; text-align:center;">
              Сюжет
            </th>
            <th scope="col" style="width:7%; text-align:center; cursor: pointer" @click="sort('counts')">
              Уникальных сообщений
              <span v-if="currentSort === 'counts'">
                <span v-if="currentSortDir === 'asc'">
                  <arrow-up-icon class="fea icon-sm"></arrow-up-icon>
                </span>
                <span v-else-if="currentSortDir === 'desc'">
                  <arrow-down-icon class="fea icon-sm"></arrow-down-icon>
                </span>
              </span>
            </th>
            <th scope="col" style="width:14%; text-align:center; cursor: pointer" @click="sort('main_role_ratio')">
              Доля главного объекта (%)
              <span v-if="currentSort === 'main_role_ratio'">
                <span v-if="currentSortDir === 'asc'">
                  <arrow-up-icon class="fea icon-sm"></arrow-up-icon>
                </span>
                <span v-else-if="currentSortDir === 'desc'">
                  <arrow-down-icon class="fea icon-sm"></arrow-down-icon>
                </span>
              </span>
            </th>
            <th scope="col" style="width:7%; text-align:center; cursor: pointer" @click="sort('first_message_dt')">
              Начало сюжета
              <span v-if="currentSort === 'first_message_dt'">
                <span v-if="currentSortDir === 'asc'">
                  <arrow-up-icon class="fea icon-sm"></arrow-up-icon>
                </span>
                <span v-else-if="currentSortDir === 'desc'">
                  <arrow-down-icon class="fea icon-sm"></arrow-down-icon>
                </span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(d, idx) in sorted_bunch_details">
            <tr @click="toggle(d.bunch_id)" :class="{ opened: opened.includes(d.bunch_id) }">
              <td style="text-align:center;">
                {{ d.bunch_id }}
              </td>
              <td>
                <p>{{ d.summary }}</p>
              </td>
              <td style="text-align:center;">
                {{ d.counts }}
              </td>
              <td style="text-align:center;">
                {{ Math.round(d.main_role_ratio * 100) }}
              </td>
              <td style="text-align:center;">
                {{ d.first_message_dt }}
              </td>
            </tr>
            <tr v-if="opened.includes(d.bunch_id)">
              <td colspan="6">
                <div style="max-height:20em; width: 100%; overflow-y:auto; overflow-x:auto;">
                  <MsgsList :texts="d.texts" />
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import store from "@/store/store";

import { ArrowUpIcon, ArrowDownIcon, HelpCircleIcon } from "vue-feather-icons";

import MsgsList from "./MsgsList.vue";

export default {
  name: "role-object-24h",
  components: {
    ArrowUpIcon,
    ArrowDownIcon,
    HelpCircleIcon,
    MsgsList
  },
  data() {
    return {
      raw_series_data: [],
      bunch_details: [],
      opened: [],
      currentSort: 'main_role_ratio',
      currentSortDir: 'desc',
      series_type: "absolute",
      all_series: {
        relative: [
          {
            name: "Процент главной роли за час",
            data: [],
            type: 'line'
          },
          {
            name: "Процент второстепенной роли за час",
            data: [],
            type: 'line'
          },
          {
            name: "Процент эпизодической роли за час",
            data: [],
            type: 'line'
          },
        ],
        absolute: [
          {
            name: "Главная роль за час",
            data: [],
            type: 'line'
          },
          {
            name: "Второстепенная роль за час",
            data: [],
            type: 'line'
          },
          {
            name: "Эпизодическая роль за час",
            data: [],
            type: 'line'
          },
        ]
      },
      series: [
        {
          name: "Главная роль за последние 24 часа",
          data: [],
          type: 'line',
        },
        {
          name: "Второстепенная роль за последние 24 часа",
          data: [],
          type: 'line',
        },
        {
          name: "Эпизодическая роль за последние 24 часа",
          data: [],
          type: 'line',
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Статистика встречаемости макро-объекта "СБЕР"',
          align: 'left'
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'ddd HH:00',
            datetimeUTC: false
          }
        },
        yaxis: {
          title: {
            text: 'кол-во макро-объектов "СБЕР"'
          }
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return series[seriesIndex][dataPointIndex];
            }
          },
          x: {
            format: 'ddd HH:00'
          }
        },
      },
    };
  },

  mounted() {
    this.getPlotData();
    this.getDetailsData();
  },
  methods: {
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    getSeriesTypeText() {
      // mismatched options and name values to see the next option
      return this.series_type == "absolute" ? "Проценты" : "Абсолютные значения"
    },
    getHostname() {
      var basic_url = this.$hostname_ba;
      return basic_url;
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getDetailsData() {
      this.axios({
        method: "get",
        url: this.getHostname() + "/object-role/object-role-bunch-details",
      }).then((res) => {
        this.bunch_details = res.data.data;
      });
    },
    updateSeries() {
      this.series_type = (this.series_type == "absolute") ? "relative" : "absolute";
      this.series = (this.series_type == "absolute") ? this.all_series.absolute : this.all_series.relative;
    },
    getPlotData() {
      console.log('seding request to ', this.getHostname() + "/object-role/object-role-24h");
      this.axios({
        method: "get",
        url: this.getHostname() + "/object-role/object-role-24h",
      }).then((res) => {
        this.raw_series_data = res.data.data;
        let main_data_rel = [];
        let minor_data_rel = [];
        let episodic_data_rel = [];
        let main_data_abs = [];
        let minor_data_abs = [];
        let episodic_data_abs = [];
        for (let i = 0; i < this.raw_series_data.length; i += 1) {
          let total = this.raw_series_data[i].total;
          let dt = this.raw_series_data[i].dt;
          let main = this.raw_series_data[i].main;
          let minor = this.raw_series_data[i].minor;
          let episodic = this.raw_series_data[i].episodic;

          main_data_rel.push({
            x: dt,
            y: (total == 0) ? 0 : Math.round(main * 100 / total)
          });
          minor_data_rel.push({
            x: dt,
            y: (total == 0) ? 0 : Math.round(minor * 100 / total)
          });
          episodic_data_rel.push({
            x: dt,
            y: (total == 0) ? 0 : Math.round(episodic * 100 / total)
          });

          main_data_abs.push({
            x: dt,
            y: main
          });
          minor_data_abs.push({
            x: dt,
            y: minor
          });
          episodic_data_abs.push({
            x: dt,
            y: episodic
          });
        };

        this.all_series = {
          relative: [
            {
              name: "Процент главной роли за час",
              data: main_data_rel,
              type: 'line'
            },
            {
              name: "Процент второстепенной роли за час",
              data: minor_data_rel,
              type: 'line'
            },
            {
              name: "Процент эпизодической роли за час",
              data: episodic_data_rel,
              type: 'line'
            },
          ],
          absolute: [
            {
              name: "Главная роль за час",
              data: main_data_abs,
              type: 'line'
            },
            {
              name: "Второстепенная роль за час",
              data: minor_data_abs,
              type: 'line'
            },
            {
              name: "Эпизодическая роль за час",
              data: episodic_data_abs,
              type: 'line'
            },
          ]
        };
        this.series = (this.series_type == "absolute") ? this.all_series.absolute : this.all_series.relative;
      });
    },
  },
  computed: {
    sorted_bunch_details: function () {
      this.loading = true;
      let a = this.bunch_details.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
      this.loading = false;
      return a;
    },
  }
};
</script>

<style>
svg>text {
  fill: white;
}

.opened {
  background-color: #253649;
}

.apexcharts-tooltip.apexcharts-theme-light {
  background: rgb(31 45 61)
}

.apexcharts-text tspan {
  fill: white;
}

.apexcharts-menu {
  background: rgb(31 45 61);
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: rgb(51 65 81)
}

.apexcharts-legend-text {
  color: white !important;
}
</style>